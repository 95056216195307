<template>
<div class="q-pa-md q-mx-auto" style="min-width: 350px">
  <h4 class="text-center">Личный кабинет</h4>
  <p>Используйте логин и пароль из памятки </p>
  <q-form
    @submit.prevent="onSubmit"
    @reset="onReset"
    class="q-gutter-md"
  >
    <q-input
      dense
      filled
      v-model="data.login"
      label="Логин"
      lazy-rules
      :rules="[ val => val && val.length > 0 || 'Обязательное поле']"
    />

    <q-input
      dense
      filled
      :type="isPwd ? 'password' : 'text'"
      v-model="data.password"
      label="Пароль"
      lazy-rules
      class='q-mt-xs'
      :rules="[
        val => val !== null && val !== '' || 'Обязательное поле',
      ]"
    >
    <template v-slot:append>
        <q-icon
          :name="isPwd ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="isPwd = !isPwd"
        />
      </template>
    </q-input>

    <div class="flex justify-center">
      <q-btn
        size="sm"
        push
        icon="logout"
        glossy
        color="white"
        text-color="accent"
        label="Войти"
        type="submit"
      />
      <q-btn
        push
        size="sm"
        label="Стереть"
        type="reset"
        color="white"
        text-color="standard"
        class="q-ml-sm"
      />
    </div>
    <div class="flex justify-center">
      <q-btn
        push
        size="sm"
        type="reset"
        color="white"
        text-color="standard"
        :href='`https://esia.medsafe.tech/${hashLink}`'
        @click="setAppHash"
      >
        Войти через
        <img alt="gu-logo" width="100px" class="q-ml-xs" src="@/assets/logo-gosuslugi-ru.png">
      </q-btn>
    </div>
  </q-form>

</div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  ref,
  computed,
} from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~store/auth/authModule';
import * as crypto from 'crypto-js';
import { setCookie } from '@/utils/cookies';

export default defineComponent({
  setup() {
    const data = reactive({
      login: '',
      password: '',
    });
    const authStore = useAuthStore();
    const isLoading = ref(false);
    const isPwd = ref(true);

    const { appHash } = storeToRefs(authStore);

    const onSubmit = () => {
      authStore.signIn(data.login, data.password);
    };
    const hashLink = computed(() => {
      const now = new Date();
      const date_str = (`0${now.getDate()}`).slice(-2) + (`0${now.getMonth() + 1}`).slice(-2) + now.getFullYear();
      const hash = crypto.SHA1(`ecohospital.ru${date_str}`);
      const digestHex = hash.toString(crypto.enc.Hex);
      return digestHex;
    });
    const setAppHash = () => {
      setCookie('eco-hash', hashLink.value, { secure: true, expires: 1 });
    };
    const onReset = () => {
      data.login = '';
      data.password = '';
    };

    return {
      data,
      onSubmit,
      onReset,
      isPwd,
      hashLink,
      setAppHash,
    };
  },
});
</script>
