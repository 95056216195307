<template>
    <AppLoader v-if='processing' />
    <q-tabs
      v-if='user.show_poll || user.show_appointment'
      v-model="selectedTab"
      narrow-indicator
      dense
      align="justify"
      class="tab-anchor text-blue-12"
    >
      <q-tab
        name="documents"
        icon="description"
        label="Документы"
        @click='selectedTab = "documents"'
      />
      <q-tab
        v-if="user.show_poll"
        name="poll"
        icon="ballot"
        label="Анкета"
        @click='selectedTab = "poll"'
      />
      <q-tab
        v-if="user.show_appointment"
        name="appointment"
        icon="edit_calendar"
        label="Записаться"
        @click='selectedTab = "appointment"'
      />
    </q-tabs>

    <section class='tabs-wrapper'>
      <section v-if="selectedTab === 'documents'">
        <q-card bordered class='fix-height'>
          <q-card-section class='text-center text-blue-grey-8 q-py-sm'>
            <div class="text-h6">Мои документы</div>
            <div class="text-subtitle2">Результаты анализов и исследований</div>
          </q-card-section>
          <q-card-section
            class='q-py-sm'
            :class="{
              'q-px-xs': $q.screen.lt.sm,
            }"
          >
            <q-list separator dense style='max-width:550px;min-width:308px;' class='q-mx-auto'>
              <q-item
                v-for="visit in groupAndSortDocuments"
                :key="visit.header"
                class="column"
              >
                <q-item-label header>Визит от {{ visit.header }}</q-item-label>
                <q-item
                  clickable
                  v-ripple
                  v-for='doc in visit.documents'
                  :key='doc.id_document'
                  class='text-blue-grey-8 full-width'
                  @click='showDocument(doc.id_document)'
                >
                  <q-item-section>
                    <div>
                      <q-badge color="blue" style='max-width:30px;'>{{ doc.file_ext }}</q-badge>
                      <span class='q-ml-md'>{{ doc.doc_name }}</span>
                    </div>
                  </q-item-section>
                  <q-item-section side>
                    <q-item-label caption>{{ doc.date_doc }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-item>
            </q-list>
          </q-card-section>
        </q-card>
      </section>

      <section v-if="!processing && selectedTab === 'poll'">
        <q-card bordered class='fix-height'>
          <q-card-section class='text-center text-blue-grey-8 q-py-sm'>
            <div class="text-h6">Анкетирование сотрудника</div>
            <div class="text-subtitle2">Перед приемом у доверенного врача</div>
          </q-card-section>
          <q-card-section v-if='done' class='text-center'>
            <h6 class='q-my-none'>Ответы сохранены</h6>
            <p>Спасибо за уделенное время!</p>
            <q-btn
              color="light-green-6"
              label="Вернуться к вопросам"
              icon="ballot"
              @click='done = false; step = 1;'
            />
          </q-card-section>
          <q-card-section
            v-else
            class='q-py-sm'
            :class="{
              'q-px-xs': $q.screen.lt.sm,
            }"
          >
            <q-stepper
              v-model="step"
              vertical
              :header-nav='user.poll_max_chapter === poll.length'
              active-color="blue-grey-8"
              done-color="light-green-6"
              animated
              keep-alive
            >
              <q-step
                v-for="chapter in poll"
                :key="chapter.chapter_number"
                :name="chapter.chapter_number"
                :title="`${chapter.chapter_number}. ${chapter.chapter}`"
                :caption="chapter.description"
                icon="ballot"
                :done="step > chapter.chapter_number"
              >
                <q-list>
                  <q-item
                    v-for="question in chapter.questions"
                    :key="question.number"
                    class='q-px-xs'
                  >
                    <Suspense>
                      <component
                        :is="defineFieldType(question.type)"
                        :context="question"
                        @hoistAnswer='captureAnswer'
                      />
                      <template #fallback>
                        <q-inner-loading :showing="true" label="Загрузка...">
                          <AppLoader />
                        </q-inner-loading>
                      </template>
                    </Suspense>
                  </q-item>
                </q-list>

                <q-stepper-navigation>
                  <q-btn
                    v-if="chapter.chapter_number > 1"
                    flat
                    @click="prevStep(chapter)"
                    color="accent"
                    label="Назад"
                    icon="arrow_back_ios"
                    class="q-mr-sm"
                  />
                  <q-btn
                    v-if="chapter.chapter_number < poll.length"
                    @click="nextStep(chapter)"
                    color="light-green-6"
                    label="Далее"
                    icon="arrow_forward_ios"
                  />
                  <q-btn
                    v-if="chapter.chapter_number === poll.length"
                    @click="finishPoll(chapter)"
                    color="light-green-6"
                    label="Завершить"
                    icon="done"
                  />
                </q-stepper-navigation>
              </q-step>
            </q-stepper>
          </q-card-section>
        </q-card>
      </section>
      <section v-if="!processing && selectedTab === 'appointment'">
        <q-card bordered class='fix-height'>
          <q-card-section class='text-center text-blue-grey-8 q-py-sm'>
            <div class="text-h6">Онлайн запись на прием</div>
            <div class="text-subtitle2">Укажите удобные для Вас дату и время</div>
            <div v-if='appointmentMessage' class="text-caption text-light-green-6">
              {{ appointmentMessage }}
            </div>
          </q-card-section>
          <q-card-section
            v-if='!updating'
            class='q-py-sm'
            :class="{
              'row': $q.screen.gt.sm,
              'column': $q.screen.lt.md,
            }"
          >
            <q-date
              minimal
              color="blue-12"
              :class="{
                'full-width': $q.screen.lt.sm,
              }"
              v-model="appointmentDate"
              :options="availableDays"
            />
            <section v-if="appointmentDate">
              <q-btn-toggle
                :class="{
                  'force-wrap': $q.screen.lt.sm,
                  'q-mt-sm': $q.screen.lt.md,
                  'q-ml-sm': $q.screen.gt.sm
                }"
                color="light"
                text-color="dark"
                v-model="appointmentTime"
                toggle-color="blue-12"
                :options="availableTimes"
              />
              <q-input
                v-model="phone"
                :class="{
                  'q-ml-sm': $q.screen.gt.xs
                }"
                class="q-mt-sm"
                type="tel"
                label="Номер телефона"
                mask="+7 (###) ### - ####"
                fill-mask
              >
                <template v-slot:prepend>
                  <q-icon name="phone" />
                </template>
              </q-input>
              <q-btn
                :class="{
                  'q-ml-sm': $q.screen.gt.xs
                }"
                class="q-mt-md"
                color="light-green-6"
                text-color="white"
                push
                :disable='sending'
                icon="event_available"
                label="Записаться"
                :loading="sending"
                @click='createAppointment'
              >
                <template v-slot:loading>
                  <q-spinner-hourglass />
                </template>
              </q-btn>
            </section>
          </q-card-section>
          <AppLoader v-else />
        </q-card>
      </section>
    </section>
</template>

<script lang="ts">
import {
  defineAsyncComponent,
  reactive,
  computed,
  ref,
  onMounted,
  watch,
} from 'vue';
import { storeToRefs } from 'pinia';
import { date, dom, useQuasar } from 'quasar';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { useEcoHospitalStore } from '~store/ecohospital/ecohospitalModule';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '@/store';
import AppLoader from '~components/AppLoader.vue';
import {
  Chapter,
  Question,
  Slot,
  UserDocument,
  GroupedDocuments,
} from '~store/ecohospital/types';

export default { name: 'DashboardView' };
</script>

<script setup lang="ts">

const { style } = dom;
const authStore = useAuthStore();
const ecoStore = useEcoHospitalStore();
const { poll, updating } = storeToRefs(ecoStore);
const { user } = storeToRefs(authStore);
const step = ref(1);
const done = ref(false);
const appointmentDate = ref(null);
const appointmentTime = ref('');
const calendar = ref<Slot[]>([]);
const phone = ref('');
const processing = ref(false);
const sending = ref(false);
const appointmentAccess = ref(false);
const selectedTab = ref('documents');
const documents = ref<UserDocument[]>([]);
const appointmentMessage = ref('');

onMounted(async () => {
  if (authStore.isAuth) {
    processing.value = true;
    try {
      documents.value = await ecoStore.getDocuments();
      if (user.value?.show_poll) {
        appointmentAccess.value = true;
        step.value = user.value?.poll_max_chapter ?? 1;
        await ecoStore.getPoll();
        if (user.value?.poll_max_chapter === poll.value.length) {
          done.value = true;
        }
      }
      if (user.value?.show_appointment) {
        const availableSlots = await ecoStore.getAppointmenCalendar();
        if (availableSlots.length) {
          calendar.value = availableSlots;
        }
        if (user.value?.tel) {
          phone.value = user.value?.tel;
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      processing.value = false;
    }
  }
});

const availableDays = computed(() => calendar.value.map((i: any) => date.formatDate(i.date, 'YYYY/MM/DD')));

const availableTimes = computed(() => {
  const calendarIndex = availableDays.value.findIndex((i) => i === appointmentDate.value);
  if (calendarIndex > -1) {
    return calendar.value[calendarIndex].times.split(',').map((i) => ({ label: i.split(':').slice(0, 2).join(':'), value: i }));
  }
  return [];
});

const parseDate = (dateStr: string): Date => {
  const [day, month, year] = dateStr.split('.').map(Number);
  return new Date(year, month - 1, day);
};

const groupAndSortDocuments = computed((): GroupedDocuments[] => {
  const sortedDocs = [...documents.value].sort(
    (a, b) => parseDate(b.datebegin).getTime() - parseDate(a.datebegin).getTime(),
  );

  const groupsMap = new Map<string, UserDocument[]>();
  // eslint-disable-next-line no-restricted-syntax
  for (const doc of sortedDocs) {
    if (!groupsMap.has(doc.datebegin)) {
      groupsMap.set(doc.datebegin, []);
    }
    groupsMap.get(doc.datebegin)?.push(doc);
  }
  return Array.from(groupsMap.entries()).map(([header, documents]) => ({
    header,
    documents,
  }));
});

const defineFieldType = computed(() => (type: string) => {
  try {
    const component = defineAsyncComponent(
      () => import(`~components/fields/${type}Field.vue`),
    );
    return component;
  } catch (error: any) {
    throw new Error(error);
  }
});

const captureAnswer = (context: any) => {
  const qI = poll.value[step.value - 1].questions
    .findIndex((i: Question) => i.number === context.number);
  if (qI > -1) {
    [...poll.value[step.value - 1].questions[qI].answers] = context.answers;
  }
};

const prevStep = async (chapter: Chapter) => {
  await ecoStore.sendChaptersAnswers({
    page: step.value,
    chapter,
  });
  step.value = chapter.chapter_number - 1;
};

const nextStep = async (chapter: Chapter) => {
  await ecoStore.sendChaptersAnswers({
    page: step.value,
    chapter,
  });
  step.value = chapter.chapter_number + 1;
};

const finishPoll = async (chapter: Chapter) => {
  await ecoStore.sendChaptersAnswers({
    page: step.value,
    chapter,
  });
  done.value = true;
  useMainStore().showMessage('Спасибо, за уделенное время!');
};

const createAppointment = async () => {
  if (!appointmentTime.value) {
    useMainStore().showMessage('Укажите желаемое время приема!');
    return;
  }
  if (phone.value.includes('_')) {
    useMainStore().showMessage('Неверный формат номера телефона!');
    return;
  }
  const cleaned = phone.value.replace(/\D/g, '');
  const params = {
    date: date.formatDate(appointmentDate.value ?? '', 'YYYY-MM-DD'),
    time: appointmentTime.value.trim(),
    tel: `+${cleaned}`,
  };
  sending.value = true;
  const [appointment] = await ecoStore.createAppointment(params);
  sending.value = false;
  console.log(appointment);
  if (appointment.err_text.includes('Ваша заявка принята')) {
    appointmentMessage.value = appointment.err_text;
    const busySlotIndex = availableTimes.value.findIndex((i) => i.value === appointmentTime.value);
    if (busySlotIndex > -1) {
      availableTimes.value.splice(busySlotIndex, 1);
    }
  }
};

const showDocument = async (id_document: number) => {
  const path = await ecoStore.getFilePath(id_document);
  if (path[0].hash) {
    window.open(`https://host2.medsafe.tech/${path[0].hash}`, '_tab');
  } else {
    useMainStore().showMessage('Извините, не смогли найти документ.');
  }
};
</script>

<style lang="scss" scope>
.tab-anchor {
  position: fixed;
  z-index: 100000;
  width: 100%;
  // top: 50px;
  left: 0;
  background: #fff;
}
// .tabs-wrapper {
//   margin-top: 60px;
// }
.fix-height {
  overflow: scroll;
  max-height: calc(100vh - 162px);
}
.force-wrap {
  flex-wrap: wrap !important;
  justify-content: space-around;
}
.list-borderless {
  border: none !important;

  .q-item {
    border: none !important;
    min-height: 40px !important;
    margin-bottom: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
  input {
    display: none;
  }
}
.pinnedItem {
  padding-left: 1rem !important;
  padding-bottom: 0.5rem !important;
  padding-top: 0 !important;
}
.tree-selection {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2000;
  background-color: ghostwhite;
}
.tree-staff-list .q-item {
  padding: 8px 12px;
}
.q-dialog__inner {
  padding: 0 !important;
}
.q-dialog__inner .q-card__section {
  padding: 0 !important;
}
.q-dialog__inner .q-card__section .q-btn-group {
  width: 100%;
  height: 50px;
}
.post-badge {
  word-wrap: break-word;
  white-space: normal !important;
}
// .q-slide-item__right > div,
// .q-slide-item__left > div {
//   width: 40%;
// }
.tree-staff-list .q-btn__content {
  flex-direction: column;
}
.small-note {
  font-size: 0.6rem;
  font-weight: 500;
}
.tree-staff-list .q-slide-item__right,
.tree-staff-list .q-slide-item__left {
  padding: 8px 0 !important;
}
.tree-staff-list .q-slide-item .q-btn {
  padding-left: 4px;
  padding-right: 4px;
}

.tree-staff-list {
  overflow-x: hidden;
}

// .tree-staff-list .q-slide-item__left {
//   justify-content: flex-end !important;
// }
// .transformed-right .q-slide-item__content {
//   transform: translateX(-40%) !important;
// }
// .transformed-left .q-slide-item__content {
//   transform: translateX(40%) !important;
// }
.warning-notify {
  background-color: #ffe395 !important;
}
.patient-info .q-banner__avatar {
  align-self: center !important;
}
.side-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.personal-info {
  border-bottom: 1px solid #000;
}
.info-avatar {
  margin: auto;
  font-size: 2rem !important;
}
.draft-item {
  padding: 0.5rem !important;
}
.draft-item .q-btn-group {
  height: auto !important;
}
.draft-wrapper {
  overflow-y: scroll;
  max-height: 70vh;
}

.patient-doc-menu.q-menu {
  width: 100%;
  max-width: 95vw !important;
  left: 10px !important;
}
.patient-doc-menu.q-menu .q-item__section {
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
}
.patient-doc-menu.q-menu .q-item__section .q-badge {
  font-size: 1rem;
}
.doc-ready-icon {
  font-size: 1.2rem;
  vertical-align: -webkit-baseline-middle;
  padding-right: 0.5rem;
}
.ready {
  background-color: #e1fce1;
}
.in-progress {
  background-color: #fce1e1;
}
.tab-header {
  font-size: 0.75rem;
}
.patients-tab-header {
  line-height: 12px;
}
.ready .doc-ready-icon {
  color: green;
}
.in-progress .doc-ready-icon {
  color: red;
}
.patient-documents-btn .q-btn__content {
  flex-direction: row !important;
}
.draft-dialog .q-item__section--main ~ .q-item__section--side {
  padding-left: 0 !important;
}
.side-wrapper {
  max-width: 116px !important;
  text-align: end;
}
.custom-skeleton {
  background: rgba(0, 0, 0, 0.2) !important;
}
</style>
