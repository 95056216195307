import script from "./DashboardView.vue?vue&type=script&setup=true&lang=ts"
export * from "./DashboardView.vue?vue&type=script&setup=true&lang=ts"

import "./DashboardView.vue?vue&type=style&index=0&id=30700a7d&lang=scss&scope=true"

const __exports__ = script;

export default __exports__
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QSlideItem from 'quasar/src/components/slide-item/QSlideItem.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';import Ripple from 'quasar/src/directives/ripple/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QTab,QCard,QCardSection,QList,QItem,QItemLabel,QItemSection,QBadge,QBtn,QStepper,QStep,QInnerLoading,QStepperNavigation,QDate,QBtnToggle,QInput,QIcon,QSpinnerHourglass,QDialog,QBtnGroup,QSlideItem,QBanner,QMenu});qInstall(script, 'directives', {Ripple});
