import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, Suspense as _Suspense } from "vue"

const _hoisted_1 = { class: "tabs-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "q-ml-md" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "text-caption text-light-green-6"
}
const _hoisted_7 = { key: 0 }


import {
  defineAsyncComponent,
  reactive,
  computed,
  ref,
  onMounted,
  watch,
} from 'vue';
import { storeToRefs } from 'pinia';
import date from 'quasar/src/utils/date/date.js';import dom from 'quasar/src/utils/dom/dom.js';import useQuasar from 'quasar/src/composables/use-quasar/use-quasar.js';;
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { useEcoHospitalStore } from '~store/ecohospital/ecohospitalModule';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '@/store';
import AppLoader from '~components/AppLoader.vue';
import {
  Chapter,
  Question,
  Slot,
  UserDocument,
  GroupedDocuments,
} from '~store/ecohospital/types';

const __default__ = { name: 'DashboardView' };

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


const { style } = dom;
const authStore = useAuthStore();
const ecoStore = useEcoHospitalStore();
const { poll, updating } = storeToRefs(ecoStore);
const { user } = storeToRefs(authStore);
const step = ref(1);
const done = ref(false);
const appointmentDate = ref(null);
const appointmentTime = ref('');
const calendar = ref<Slot[]>([]);
const phone = ref('');
const processing = ref(false);
const sending = ref(false);
const appointmentAccess = ref(false);
const selectedTab = ref('documents');
const documents = ref<UserDocument[]>([]);
const appointmentMessage = ref('');

onMounted(async () => {
  if (authStore.isAuth) {
    processing.value = true;
    try {
      documents.value = await ecoStore.getDocuments();
      if (user.value?.show_poll) {
        appointmentAccess.value = true;
        step.value = user.value?.poll_max_chapter ?? 1;
        await ecoStore.getPoll();
        if (user.value?.poll_max_chapter === poll.value.length) {
          done.value = true;
        }
      }
      if (user.value?.show_appointment) {
        const availableSlots = await ecoStore.getAppointmenCalendar();
        if (availableSlots.length) {
          calendar.value = availableSlots;
        }
        if (user.value?.tel) {
          phone.value = user.value?.tel;
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      processing.value = false;
    }
  }
});

const availableDays = computed(() => calendar.value.map((i: any) => date.formatDate(i.date, 'YYYY/MM/DD')));

const availableTimes = computed(() => {
  const calendarIndex = availableDays.value.findIndex((i) => i === appointmentDate.value);
  if (calendarIndex > -1) {
    return calendar.value[calendarIndex].times.split(',').map((i) => ({ label: i.split(':').slice(0, 2).join(':'), value: i }));
  }
  return [];
});

const parseDate = (dateStr: string): Date => {
  const [day, month, year] = dateStr.split('.').map(Number);
  return new Date(year, month - 1, day);
};

const groupAndSortDocuments = computed((): GroupedDocuments[] => {
  const sortedDocs = [...documents.value].sort(
    (a, b) => parseDate(b.datebegin).getTime() - parseDate(a.datebegin).getTime(),
  );

  const groupsMap = new Map<string, UserDocument[]>();
  // eslint-disable-next-line no-restricted-syntax
  for (const doc of sortedDocs) {
    if (!groupsMap.has(doc.datebegin)) {
      groupsMap.set(doc.datebegin, []);
    }
    groupsMap.get(doc.datebegin)?.push(doc);
  }
  return Array.from(groupsMap.entries()).map(([header, documents]) => ({
    header,
    documents,
  }));
});

const defineFieldType = computed(() => (type: string) => {
  try {
    const component = defineAsyncComponent(
      () => import(`~components/fields/${type}Field.vue`),
    );
    return component;
  } catch (error: any) {
    throw new Error(error);
  }
});

const captureAnswer = (context: any) => {
  const qI = poll.value[step.value - 1].questions
    .findIndex((i: Question) => i.number === context.number);
  if (qI > -1) {
    [...poll.value[step.value - 1].questions[qI].answers] = context.answers;
  }
};

const prevStep = async (chapter: Chapter) => {
  await ecoStore.sendChaptersAnswers({
    page: step.value,
    chapter,
  });
  step.value = chapter.chapter_number - 1;
};

const nextStep = async (chapter: Chapter) => {
  await ecoStore.sendChaptersAnswers({
    page: step.value,
    chapter,
  });
  step.value = chapter.chapter_number + 1;
};

const finishPoll = async (chapter: Chapter) => {
  await ecoStore.sendChaptersAnswers({
    page: step.value,
    chapter,
  });
  done.value = true;
  useMainStore().showMessage('Спасибо, за уделенное время!');
};

const createAppointment = async () => {
  if (!appointmentTime.value) {
    useMainStore().showMessage('Укажите желаемое время приема!');
    return;
  }
  if (phone.value.includes('_')) {
    useMainStore().showMessage('Неверный формат номера телефона!');
    return;
  }
  const cleaned = phone.value.replace(/\D/g, '');
  const params = {
    date: date.formatDate(appointmentDate.value ?? '', 'YYYY-MM-DD'),
    time: appointmentTime.value.trim(),
    tel: `+${cleaned}`,
  };
  sending.value = true;
  const [appointment] = await ecoStore.createAppointment(params);
  sending.value = false;
  console.log(appointment);
  if (appointment.err_text.includes('Ваша заявка принята')) {
    appointmentMessage.value = appointment.err_text;
    const busySlotIndex = availableTimes.value.findIndex((i) => i.value === appointmentTime.value);
    if (busySlotIndex > -1) {
      availableTimes.value.splice(busySlotIndex, 1);
    }
  }
};

const showDocument = async (id_document: number) => {
  const path = await ecoStore.getFilePath(id_document);
  if (path[0].hash) {
    window.open(`https://host2.medsafe.tech/${path[0].hash}`, '_tab');
  } else {
    useMainStore().showMessage('Извините, не смогли найти документ.');
  }
};

return (_ctx: any,_cache: any) => {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_inner_loading = _resolveComponent("q-inner-loading")!
  const _component_q_stepper_navigation = _resolveComponent("q-stepper-navigation")!
  const _component_q_step = _resolveComponent("q-step")!
  const _component_q_stepper = _resolveComponent("q-stepper")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_btn_toggle = _resolveComponent("q-btn-toggle")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_spinner_hourglass = _resolveComponent("q-spinner-hourglass")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (processing.value)
      ? (_openBlock(), _createBlock(AppLoader, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(user).show_poll || _unref(user).show_appointment)
      ? (_openBlock(), _createBlock(_component_q_tabs, {
          key: 1,
          modelValue: selectedTab.value,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((selectedTab).value = $event)),
          "narrow-indicator": "",
          dense: "",
          align: "justify",
          class: "tab-anchor text-blue-12"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_q_tab, {
              name: "documents",
              icon: "description",
              label: "Документы",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (selectedTab.value = "documents"))
            }),
            (_unref(user).show_poll)
              ? (_openBlock(), _createBlock(_component_q_tab, {
                  key: 0,
                  name: "poll",
                  icon: "ballot",
                  label: "Анкета",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (selectedTab.value = "poll"))
                }))
              : _createCommentVNode("", true),
            (_unref(user).show_appointment)
              ? (_openBlock(), _createBlock(_component_q_tab, {
                  key: 1,
                  name: "appointment",
                  icon: "edit_calendar",
                  label: "Записаться",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (selectedTab.value = "appointment"))
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_1, [
      (selectedTab.value === 'documents')
        ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
            _createVNode(_component_q_card, {
              bordered: "",
              class: "fix-height"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, { class: "text-center text-blue-grey-8 q-py-sm" }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createElementVNode("div", { class: "text-h6" }, "Мои документы", -1),
                    _createElementVNode("div", { class: "text-subtitle2" }, "Результаты анализов и исследований", -1)
                  ])),
                  _: 1
                }),
                _createVNode(_component_q_card_section, {
                  class: _normalizeClass(["q-py-sm", {
              'q-px-xs': _ctx.$q.screen.lt.sm,
            }])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_q_list, {
                      separator: "",
                      dense: "",
                      style: {"max-width":"550px","min-width":"308px"},
                      class: "q-mx-auto"
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupAndSortDocuments.value, (visit) => {
                          return (_openBlock(), _createBlock(_component_q_item, {
                            key: visit.header,
                            class: "column"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_q_item_label, { header: "" }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Визит от " + _toDisplayString(visit.header), 1)
                                ]),
                                _: 2
                              }, 1024),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visit.documents, (doc) => {
                                return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                  clickable: "",
                                  key: doc.id_document,
                                  class: "text-blue-grey-8 full-width",
                                  onClick: ($event: any) => (showDocument(doc.id_document))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", null, [
                                          _createVNode(_component_q_badge, {
                                            color: "blue",
                                            style: {"max-width":"30px"}
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(doc.file_ext), 1)
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createElementVNode("span", _hoisted_3, _toDisplayString(doc.doc_name), 1)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_q_item_section, { side: "" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_q_item_label, { caption: "" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(doc.date_doc), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])), [
                                  [_directive_ripple]
                                ])
                              }), 128))
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!processing.value && selectedTab.value === 'poll')
        ? (_openBlock(), _createElementBlock("section", _hoisted_4, [
            _createVNode(_component_q_card, {
              bordered: "",
              class: "fix-height"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, { class: "text-center text-blue-grey-8 q-py-sm" }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createElementVNode("div", { class: "text-h6" }, "Анкетирование сотрудника", -1),
                    _createElementVNode("div", { class: "text-subtitle2" }, "Перед приемом у доверенного врача", -1)
                  ])),
                  _: 1
                }),
                (done.value)
                  ? (_openBlock(), _createBlock(_component_q_card_section, {
                      key: 0,
                      class: "text-center"
                    }, {
                      default: _withCtx(() => [
                        _cache[11] || (_cache[11] = _createElementVNode("h6", { class: "q-my-none" }, "Ответы сохранены", -1)),
                        _cache[12] || (_cache[12] = _createElementVNode("p", null, "Спасибо за уделенное время!", -1)),
                        _createVNode(_component_q_btn, {
                          color: "light-green-6",
                          label: "Вернуться к вопросам",
                          icon: "ballot",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => {done.value = false; step.value = 1;})
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_q_card_section, {
                      key: 1,
                      class: _normalizeClass(["q-py-sm", {
              'q-px-xs': _ctx.$q.screen.lt.sm,
            }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_stepper, {
                          modelValue: step.value,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((step).value = $event)),
                          vertical: "",
                          "header-nav": _unref(user).poll_max_chapter === _unref(poll).length,
                          "active-color": "blue-grey-8",
                          "done-color": "light-green-6",
                          animated: "",
                          "keep-alive": ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(poll), (chapter) => {
                              return (_openBlock(), _createBlock(_component_q_step, {
                                key: chapter.chapter_number,
                                name: chapter.chapter_number,
                                title: `${chapter.chapter_number}. ${chapter.chapter}`,
                                caption: chapter.description,
                                icon: "ballot",
                                done: step.value > chapter.chapter_number
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_q_list, null, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chapter.questions, (question) => {
                                        return (_openBlock(), _createBlock(_component_q_item, {
                                          key: question.number,
                                          class: "q-px-xs"
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(), _createBlock(_Suspense, null, {
                                              fallback: _withCtx(() => [
                                                _createVNode(_component_q_inner_loading, {
                                                  showing: true,
                                                  label: "Загрузка..."
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(AppLoader)
                                                  ]),
                                                  _: 1
                                                })
                                              ]),
                                              default: _withCtx(() => [
                                                (_openBlock(), _createBlock(_resolveDynamicComponent(defineFieldType.value(question.type)), {
                                                  context: question,
                                                  onHoistAnswer: captureAnswer
                                                }, null, 40, ["context"]))
                                              ]),
                                              _: 2
                                            }, 1024))
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_q_stepper_navigation, null, {
                                    default: _withCtx(() => [
                                      (chapter.chapter_number > 1)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 0,
                                            flat: "",
                                            onClick: ($event: any) => (prevStep(chapter)),
                                            color: "accent",
                                            label: "Назад",
                                            icon: "arrow_back_ios",
                                            class: "q-mr-sm"
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      (chapter.chapter_number < _unref(poll).length)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 1,
                                            onClick: ($event: any) => (nextStep(chapter)),
                                            color: "light-green-6",
                                            label: "Далее",
                                            icon: "arrow_forward_ios"
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      (chapter.chapter_number === _unref(poll).length)
                                        ? (_openBlock(), _createBlock(_component_q_btn, {
                                            key: 2,
                                            onClick: ($event: any) => (finishPoll(chapter)),
                                            color: "light-green-6",
                                            label: "Завершить",
                                            icon: "done"
                                          }, null, 8, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["name", "title", "caption", "done"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "header-nav"])
                      ]),
                      _: 1
                    }, 8, ["class"]))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!processing.value && selectedTab.value === 'appointment')
        ? (_openBlock(), _createElementBlock("section", _hoisted_5, [
            _createVNode(_component_q_card, {
              bordered: "",
              class: "fix-height"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_card_section, { class: "text-center text-blue-grey-8 q-py-sm" }, {
                  default: _withCtx(() => [
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-h6" }, "Онлайн запись на прием", -1)),
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-subtitle2" }, "Укажите удобные для Вас дату и время", -1)),
                    (appointmentMessage.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(appointmentMessage.value), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (!_unref(updating))
                  ? (_openBlock(), _createBlock(_component_q_card_section, {
                      key: 0,
                      class: _normalizeClass(["q-py-sm", {
              'row': _ctx.$q.screen.gt.sm,
              'column': _ctx.$q.screen.lt.md,
            }])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_date, {
                          minimal: "",
                          color: "blue-12",
                          class: _normalizeClass({
                'full-width': _ctx.$q.screen.lt.sm,
              }),
                          modelValue: appointmentDate.value,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((appointmentDate).value = $event)),
                          options: availableDays.value
                        }, null, 8, ["class", "modelValue", "options"]),
                        (appointmentDate.value)
                          ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
                              _createVNode(_component_q_btn_toggle, {
                                class: _normalizeClass({
                  'force-wrap': _ctx.$q.screen.lt.sm,
                  'q-mt-sm': _ctx.$q.screen.lt.md,
                  'q-ml-sm': _ctx.$q.screen.gt.sm
                }),
                                color: "light",
                                "text-color": "dark",
                                modelValue: appointmentTime.value,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((appointmentTime).value = $event)),
                                "toggle-color": "blue-12",
                                options: availableTimes.value
                              }, null, 8, ["class", "modelValue", "options"]),
                              _createVNode(_component_q_input, {
                                modelValue: phone.value,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((phone).value = $event)),
                                class: _normalizeClass([{
                  'q-ml-sm': _ctx.$q.screen.gt.xs
                }, "q-mt-sm"]),
                                type: "tel",
                                label: "Номер телефона",
                                mask: "+7 (###) ### - ####",
                                "fill-mask": ""
                              }, {
                                prepend: _withCtx(() => [
                                  _createVNode(_component_q_icon, { name: "phone" })
                                ]),
                                _: 1
                              }, 8, ["modelValue", "class"]),
                              _createVNode(_component_q_btn, {
                                class: _normalizeClass([{
                  'q-ml-sm': _ctx.$q.screen.gt.xs
                }, "q-mt-md"]),
                                color: "light-green-6",
                                "text-color": "white",
                                push: "",
                                disable: sending.value,
                                icon: "event_available",
                                label: "Записаться",
                                loading: sending.value,
                                onClick: createAppointment
                              }, {
                                loading: _withCtx(() => [
                                  _createVNode(_component_q_spinner_hourglass)
                                ]),
                                _: 1
                              }, 8, ["class", "disable", "loading"])
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["class"]))
                  : (_openBlock(), _createBlock(AppLoader, { key: 1 }))
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})